body.crm-view {
  background-color: $page-bg-color;
  color: $text-color;
  width: 100%;
  .container {
    margin-top: 1.5rem;
    padding: 0 12px;
    row-gap: 1.5rem;
  }
  .row {
    display: flex;
    flex-wrap: wrap;
    row-gap: 24px;
    margin-left: -12px;
    margin-right: -12px;
    .col-1,
    .col-2,
    .col-3,
    .col-4,
    .col-5,
    .col-6,
    .col-7,
    .col-8,
    .col-9,
    .col-10,
    .col-11,
    .col-12 {
      padding-left: 12px;
      padding-right: 12px;
    }
    &:not(.col-1):not(.col-2):not(.col-3):not(.col-4):not(.col-5):not(
        .col-6
      ):not(.col-7):not(.col-8):not(.col-9):not(.col-10):not(.col-11):not(
        .col-12
      ) {
      width: calc(100% + 24px);
    }
  }
  .card {
    padding: 24px !important;
    gap: 24px;
    border-radius: 16px;
    border: 1px solid $border-color;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.05);

    background-color: $card-bg-color;
    height: 100%;
    h6 {
      margin: 0;
      font-size: 18px;
      font-weight: 700;
      line-height: 1.5em;
    }
  }
  .form-control {
    padding: 5px 11px;
    border-radius: 4px;
    border: 1px solid $input-border-color;
    background-color: $card-bg-color;
    color: $text-color;
    box-shadow: none;
  }
  .navbar {
    padding: 0;
    border-radius: 4px;
    border: 1px solid $input-border-color;
    background-color: $card-bg-color;
  }
  .nav-item {
    padding: 6px 18px 6px 12px !important;
    align-items: center;
    background-color: $card-bg-color;
    &.active {
      background-color: $bg-active-color;
    }
  }
  .nav-link {
    display: flex;
    align-items: center;
    gap: 6px;
    padding: 0;
    color: $text-color;
    font-weight: bold;
    white-space: unset;
  }
  .form-group {
    width: 100%;
  }
  .accordion-item {
    background-color: transparent;
  }
  .accordion-button {
    padding: 0;
    box-shadow: none;
    border: none;
    background-color: transparent;
    color: $link-color;
    &::after {
      background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%230c63e4%27%3e%3cpath fill-rule=%27evenodd%27 d=%27M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z%27/%3e%3c/svg%3e");
    }
  }
  .mobile-only {
    @media (min-width: $tablet_v) {
      display: none !important;
    }
  }
  .instructions-note {
    padding: 10px;
    border-radius: 4px;
    background-color: $bg-active-color;
    color: $danger-color;
    font-size: 16px;
    font-weight: 700;
    line-height: 1.5em;
  }

  .user-info-page {
    display: flex;
    flex-direction: column;
    gap: 24px;
    .action-buttom-container {
      flex-direction: row;
      justify-content: center;
      gap: 24px;
    }
  }

  .user-info-disabled-message {
    display: flex;
    align-items: center;
    column-gap: 6px;
    .material-icons {
      font-size: 30px;
    }
    .user-info-disabled-message-wrapper {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      column-gap: 6px;
      font-size: 20px;
    }
  }
  .edit-btn {
    .button-icon {
      color: $link-color;
    }
  }
  .delete-btn {
    .button-icon {
      color: $danger-color;
    }
  }

  //--- Date picker custom styles
  .rs-picker {
    .rs-picker-toggle-textbox,
    .rs-picker-toggle,
    .rs-picker-toggle-active {
      background-color: $card-bg-color !important;
      border: 1px solid $border-color;
      border-radius: 4px;
      font-size: 14px;
      font-weight: 700;
      box-shadow: none;
      .rs-picker-toggle-value {
        color: $title-text-color !important;
      }
      &:hover,
      &:focus {
        background-color: $bg-active-color !important;
        border: 1px solid $border-color;
      }
    }
  }
  .rs-picker-date-menu {
    z-index: 2000;
    background-color: $card-bg-color;
    border: 1px solid $card-bg-color;
    box-shadow: 0px 16px 16px 0px rgba(0, 0, 0, 0.2);
    .rs-calendar-time-dropdown {
      background-color: $card-bg-color;
    }
    .rs-calendar-time-dropdown-cell {
      color: $title-text-color;
    }
    .rs-calendar-table-cell {
      color: $title-text-color !important;
      &:hover {
        .rs-calendar-table-cell-content {
          color: $title-text-color !important;
        }
      }
    }
    .rs-calendar-month-dropdown-row {
      background-color: $card-bg-color;
    }
    .rs-calendar-time-dropdown-column-title {
      background-color: unset;
    }
  }
  //---------------------------------------

  // Managers page styles
  .manager-page {
    .user-action-container {
      display: flex;
      flex-direction: column;
      gap: 12px;
    }
    .user-action-top-container {
      display: flex;
      flex-direction: column;
      gap: 12px;
    }
    .add-manager-form {
      display: flex;
      flex-direction: column;
      align-items: start;

      gap: 12px;
    }
    .react-bootstrap-table {
      overflow-x: auto;
    }
    .react-bootstrap-table table {
      width: auto;
    }
    table {
      .action-container {
        display: flex;
        gap: 12px;
      }
    }
    .edit-manager-form {
      display: flex;
      flex-direction: column;
      gap: 12px;
    }

    @media (min-width: $tablet_v) {
      .add-manager-form {
        flex-direction: row;
        align-items: end;
      }
      .user-action-top-container {
        flex-direction: row;
      }
    }
    @media (min-width: $mobile_h) {
      .react-bootstrap-table table {
        width: 100%;
      }
    }
  }
  // --------------------------------

  // Managers page styles
  .managers-group-page {
    .content-modal-container {
      display: flex;
      flex-direction: column;
      gap: 24px;
    }
    .manager-group-item {
      display: flex;
      flex-direction: column;
      gap: 12px;
    }
    .group-name-wrapper {
      word-break: break-all;
    }
    .action-container {
      display: flex;
      gap: 12px;
      white-space: nowrap;
    }
    @media (min-width: $mobile_h) {
      .manager-group-item {
        flex-direction: row;
        justify-content: space-between;
      }
    }
  }
  // --------------------------------

  // Find duplicates page styles
  .find-duplicates-container {
    .header-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 24px;
    }
    .title-container {
      display: flex;
      flex-direction: column;
      gap: 12px;
    }
    .duplicates-actions {
      display: flex;
      gap: 24px;
    }
    .nav-container {
      display: flex;
      flex-direction: column;
    }
    .nav-item {
      padding: 0 !important;
      border: none;
    }
    .nav-link {
      padding: 6px 12px;
      height: 36px;
      border-radius: 4px 4px 0px 0px;
      border-top: 1px solid $input-border-color;
      border-right: 1px solid $input-border-color;
      border-left: 1px solid $input-border-color;
      border-bottom-width: 0;
      text-decoration: none;
      color: $title-text-color;
      isolation: auto;
      &.active {
        background-color: $input-border-color;
      }
      .material-icons {
        color: $link-color;
      }
    }
    .nav-tabs {
      gap: 2px;
      border: none;
    }
    .nav-bottom-line {
      height: 8px;
      background-color: $input-border-color;
      border-radius: 0px 0px 4px 4px;
    }
    .sorting-table {
      margin-top: unset;
    }

    .react-bootstrap-table {
      overflow-x: auto;
    }
    .react-bootstrap-table table {
      width: auto;
    }

    @media (min-width: $tablet_v) {
      .title-container {
        flex-direction: row;
      }
    }

    @media (min-width: $mobile_h) {
      .react-bootstrap-table table {
        width: 100%;
      }
    }
  }
  // --------------------------------

  // Scripts page styles
  .scripts-page-container {
    .scripts-heading {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 12px;
    }
    .script-item-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 12px;
    }
    .script-action-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 12px;
    }
    .script-link {
      word-break: break-word;
    }
  }
  // --------------------------------

  // Tests page styles
  .tests-page-container {
    .tests-heading {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 12px;
    }
    .test-item-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 12px;
    }
    .test-action-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 12px;
    }
    .test-link {
      word-break: break-word;
    }
  }
  // --------------------------------

  // Tests page styles
  .my-tests-page-container {
    .test-item {
      display: flex;
      flex-direction: column;
      gap: 24px;
      justify-content: space-between;
    }
    @media (min-width: $tablet_v) {
      .test-item {
        flex-direction: row;
      }
      .test-passed-informaition {
        min-width: fit-content;
      }
    }
  }
  // --------------------------------

  // Pass-test page styles
  .pass-test-page {
    .test-name {
      font-size: 16px;
    }
    .questions-block {
      padding-left: 12px;
      display: flex;
      flex-direction: column;
      gap: 24px;
    }
    .question-block {
      display: flex;
      flex-direction: column;
      gap: 24px;
    }
    .answer-block {
      display: flex;
      flex-direction: column;
      gap: 12px;
      padding-left: 12px;
      > * {
        width: 100%;
      }
      .selected-option {
        overflow: hidden;
      }
    }
    .wide-answer-block {
      padding-left: 12px;
    }

    .answers-list {
      display: flex;
      flex-direction: column;
      gap: 12px;
    }
    .text-footer {
      display: flex;
      flex-direction: row;
      justify-content: end;
    }
    @media (min-width: $tablet_v) {
      .answer-block {
        flex-direction: row;
        > * {
          width: 50%;
        }
      }
    }
  }
  // --------------------------------

  // Documents page styles
  .documents-page {
    .documents-container {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      gap: 24px;
    }
    .document-wrapper {
      position: relative;
      &:hover {
        .img-backdrop {
          opacity: 1;
        }
      }
    }
    .document-trigger {
      position: absolute;
      top: 0;
      height: 100%;
      width: 100%;
    }
    .img-wrapper {
      position: relative;
    }
    .img-backdrop {
      position: absolute;
      height: 100%;
      width: 100%;
      background-color: rgba(
        37,
        37,
        37,
        0.5
      ); // the same as $card-bg-color + opacity 0.5
      opacity: 0;
      transition: opacity 0.3s;
    }
    .card-body {
      display: flex;
      justify-content: space-between;
      gap: 24px;
      padding: 0;
      .material-icons {
        color: $link-color;
        font-size: 16px;
      }
    }
    .card-title {
      margin: 0;
      color: $link-color;
      font-size: 16px;
      font-weight: 400;
      line-height: 1.5em;
    }
    @media (min-width: $tablet_v) {
      .documents-container {
        grid-template-columns: repeat(3, 1fr);
      }
    }
    @media (min-width: $tablet_h) {
      .documents-container {
        grid-template-columns: repeat(4, 1fr);
      }
    }
  }
  // --------------------------------
}

// Reporting styles
body.reporting {
  .pivot-table {
    text-align: end;
    font-size: 1rem;
  }
  #root[data-bs-theme="dark"] {
    background-color: $dark--page-bg-color;
    color: $dark--text-color;
    scrollbar-color: $dark-text-color $dark--border-color;
    .card {
      background-color: $dark--card-bg-color;
    }
    .form-control {
      background-color: $dark--card-bg-color;
      border: 1px solid $dark--border-color;
      color: inherit;
    }
    .nav-tabs .nav-link.active,
    .nav-tabs .nav-item.show .nav-link {
      background: none;
      color: inherit;
      border-color: $dark--border-color;
    }
    .nav-tabs {
      border-bottom: 1px solid $dark--border-color;
    }
    .form-select {
      border: 1px solid $dark--border-color;
      @include base-style-dark;
    }
    .dropdown {
      ul {
        @include base-style-dark;
        box-shadow: 0 6px 20px rgba(0, 0, 0, 0.5), 0 6px 20px rgba(0, 0, 0, 0.5);
      }
    }
    .select-custom-container {
      @include base-style-dark;
      #react-select-2-placeholder {
        color: #6c757d;
      }
      .select-custom-prefix__control {
        border: 1px solid $dark--border-color;
        @include base-style-dark;
      }

      .select-custom-prefix__menu {
        @include base-style-dark;
        box-shadow: 0 6px 20px rgba(0, 0, 0, 0.5), 0 6px 20px rgba(0, 0, 0, 0.5);
      }
      .select-custom-prefix__option--is-focused {
        color: black;
      }
    }
    table {
      border: 1px solid $dark--border-color;
      @include base-style-dark;
      .additional-row {
        background-color: $dark--card-secondary-bg-color;
      }
      --bs-table-hover-color: $dark--text-color;
    }
    .table-success {
      --bs-table-bg: #cacaca;
      --bs-table-hover-bg: rgba(133, 131, 131, 0.2);
    }
  }
}
// --------------------------------

// NOTE! To hide Upgrate button in tiny text editor
.tox-promotion {
  display: none;
}

// NOTE! headerClasses used to hide table header
.custom-header-class-for-hide {
  display: none;
}
