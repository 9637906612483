@import "./Footer/EditorFooter.scss";

.docs-editor-container {
  display: flex;
  flex-direction: column;
  gap: 24px;
  font-size: 16px;
  line-height: 1.5em;
  color: $title-text-color;
  .head-container {
    display: flex;
    flex-direction: column;
    align-items: start;
  }
  .button-container {
    display: flex;
    gap: 24px;
  }
  .full-width-container {
    display: flex;
    flex-direction: column;
    justify-content: start;
    gap: 24px;
    height: 100%;
  }
  .half-width-container {
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 100%;
    height: 100%;
  }
  .height-900 {
    height: 900px !important;
  }
  .height-800 {
    height: 800px !important;
  }
  .height-700 {
    height: 700px !important;
  }
  .height-650 {
    height: 650px !important;
  }
  .height-600 {
    height: 600px !important;
  }
  .height-500 {
    height: 500px !important;
  }
  .height-550 {
    height: 550px !important;
  }
  .height-400 {
    height: 400px !important;
  }
  .height-300 {
    height: 300px !important;
  }
  .width-format-1 {
    width: 100% !important;
    max-width: 780px;
  }
  .tox-tinymce {
    height: 100% !important;
  }
  .inputs-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    gap: 24px;
    .input-group-container {
      width: fit-content;
    }
    .custom-dropdown {
      width: fit-content;
    }
  }
  .quarter-width-container {
    width: 100%;
    *,
    .custom-dropdown {
      width: 100%;
    }
  }
  .half-width-inputes-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 24px;
  }
  .custom-checkbox-container {
    width: fit-content;
  }
  .adaptive-container {
    display: flex;
    flex-direction: column;
    flex: none;
    gap: 24px;
    width: 100%;
  }
  iframe {
    width: 100%;
    height: 100%;
  }
  input[type="color"] {
    min-width: 48px;
    max-width: 48px;
  }
  .custom-grid-container {
    gap: unset;

    .content-row {
      display: flex;
      flex-direction: column;
      gap: 24px;
      padding: 12px 0;
      &:not(:last-child) {
        border-bottom: 1px solid #323232;
      }
    }
    .header-row {
      display: none;
    }
  }

  @media (min-width: $mobile_h) {
    .custom-grid-container {
      display: flex;
      flex-direction: column;
      gap: 24px;
      grid-gap: 24px;
      .content-row {
        display: grid;
        gap: 12px;
        grid-template-columns: 70px 4fr 4fr 40px;
        align-items: center;
        padding: 0;
        border-bottom: none !important;
      }
      .header-row {
        display: grid;
      }
    }
  }

  @media (min-width: $tablet_v) {
    .head-container {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
    .full-width-container {
      flex-direction: row;
    }
    .half-width-container {
      justify-content: end;
      width: 50%;
      &.end-side {
        margin-left: auto;
        padding-left: 12px;
      }
    }
    .half-width-inputes-container {
      flex-wrap: nowrap;
      width: 50%;
    }
    .quarter-width-container {
      width: 25%;
    }
    .adaptive-container {
      flex: 1;
      width: auto;
    }
  }

  //table aditor styles-------
}
// Tiny editor stile------
.tox-notification {
  display: none !important;
}
